<template>
  <MenuLayout />
  <main class="flex-shrink-0">
    <HeaderLayout :name="name" />
    <div v-if="isAdmin != 'yes'" class="embed-responsive embed-responsive-21by9" style="height: 720px;">
        <iframe :src="presenceListUrl" class="embed-responsive-item"></iframe>
    </div>
    <div v-else class="container mt-4">
        <div style="display: flex; justify-content: center; align-items: center; ">
            <p>Data tidak tersedia.</p>
        </div>
    </div>
  </main>
</template>

<script>
import MenuLayout from "../components/MenuLayout.vue";
import HeaderLayout from "../components/HeaderLayout.vue";
export default {
  name: "PresenceList",
  components: { MenuLayout, HeaderLayout },
  data() {
    return {
      name: '',
      isAdmin: '',
      presenceListUrl: '',
    };
  },
  async created() {
    try {
      const status = localStorage.getItem('status');
      if (status != 'valid') {
        this.$router.push('/');
      }
      this.name = localStorage.getItem('profileName');
      this.isAdmin = localStorage.getItem('isAdmin');
      this.presenceListUrl = 'https://garuda.bkd.jatengprov.go.id/presensi_mobile/rekap_mobile/saya?token=' + localStorage.getItem('tokenMobile');
    } catch (err) {
      this.$router.push('/');
    }
  },
};
</script>