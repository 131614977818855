<template>
    <header class="header">
        <div class="row">
            <div class="col-auto px-0">
                <button class="menu-btn btn btn-link-default" type="button">
                    <img src="/img/icons/menu-outline.svg" alt="" class="icon-size-24">
                </button>
            </div>
            <div class="text-left col">
                <a class="navbar-brand" href="#">
                    <div class="icon icon-44 bg-dark text-white">
                        <img src="/img/icon44.png">
                    </div>
                    <h4>{{ name }}</h4>
                </a>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: "HeaderLayout",
    props: {
        name: {
            type: String,
            required: true,
        },
    },
};
</script>