<template>
    <MenuLayout />
    <main class="flex-shrink-0">
      <HeaderLayout :name="name" />
      <div v-if="isAdmin != 'yes'" class="embed-responsive embed-responsive-21by9" style="height: 720px;">
          <iframe :src="profileUrl" class="embed-responsive-item"></iframe>
      </div>
      <div v-else class="container mt-4">
          <div style="display: flex; justify-content: center; align-items: center; ">
              <p>Data tidak tersedia.</p>
          </div>
      </div>
    </main>
  </template>
  
  <script>
  import MenuLayout from "../components/MenuLayout.vue";
  import HeaderLayout from "../components/HeaderLayout.vue";
  import { config } from '../config.js';

  export default {
    name: "UserProfile",
    components: { MenuLayout, HeaderLayout },
    data() {
      return {
        name: '',
        isAdmin: '',
      };
    },
    computed: {
        profileUrl() {
          const token = localStorage.getItem('tokenMobile');
          return config.apiUrl+'/api/profile?token='+token;
        },
    },
    async created() {
      try {
        const status = localStorage.getItem('status');
        if (status != 'valid') {
          this.$router.push('/');
        }
        this.name = localStorage.getItem('profileName');
        this.isAdmin = localStorage.getItem('isAdmin');
      } catch (err) {
        this.$router.push('/');
      }
    },
  };
  </script>