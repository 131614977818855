import { createRouter, createWebHistory } from 'vue-router';
import UserLogin from '../views/UserLogin.vue';
import UserDashboard from '../views/UserDashboard.vue';
import PresenceList from '../views/PresenceList.vue';
import UserProfile from '../views/UserProfile.vue';
import TakeHomePay from '../views/TakeHomePay.vue';

const routes = [
  { path: '/', name: 'UserLogin', component: UserLogin },
  { path: '/dashboard', name: 'UserDashboard', component: UserDashboard },
  { path: '/presence-list', name: 'PresenceList', component: PresenceList },
  { path: '/profile', name: 'UserProfile', component: UserProfile },
  { path: '/take-home-pay', name: 'TakeHomePay', component: TakeHomePay },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
