<template>
  <main class="flex-shrink-0">
      <div class="container text-center  mt-4">
          <div class="icon icon-100 bg-dark text-white mb-4 text-center">
              <img src="/img/icon.png">
          </div>
          <h4 class="mb-4">RevArmy v2</h4>
      </div>
      <div class="container">
          <div class="login-box">
            <form @submit.prevent="handleLogin">
                <div class="form-group floating-form-group">
                    <input type="text" class="form-control floating-input" name="username" v-model="username" required>
                    <label class="floating-label">Username</label>
                </div>
                <div class="form-group floating-form-group">
                    <input type="password" name="password" class="form-control floating-input" v-model="password" required>
                    <label class="floating-label">Password</label>
                    <span v-if="error" class="invalid-feedback" role="alert" style="display: block !important;">{{ error }}</span>
                </div>
                <button type="submit" class="btn btn-block btn-info btn-lg">
                  Login {{ apiUrl }}
                </button>
            </form>
          </div>
      </div>
  </main>
</template>

<script>
import { config } from '../config.js';

export default {
  name: "UserLogin",
  data() {
    return {
      username: '',
      password: '',
      error: ''
    };
  },
  created() {
    // Periksa apakah token tersedia di localStorage
    const status = localStorage.getItem('status');
    if (status == 'valid') {
      this.$router.push('/dashboard');
    }
  },
  methods: {
    async handleLogin() {
      try {
        const response = await fetch(config.apiUrl+'/api/login', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username: this.username, password: this.password }),
        });
        const data = await response.json();

        if (response.ok) {
          if (data.status == 'valid') {
            localStorage.setItem('status', data.status);
            localStorage.setItem('isAdmin', data.isAdmin || '');
            localStorage.setItem('tokenMobile', data.token_mobile);
            localStorage.setItem('username', this.username);
            // this.$router.push('/dashboard');
            window.location.href = '/dashboard';
          } else {
            this.error = 'Username atau password salah.';
          }
        } else {
          this.error = data.message || 'Username atau password salah.';
        }
      } catch (err) {
        this.error = 'Username atau password salah.';
      }
    },
  },
};
</script>