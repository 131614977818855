<template>
  <MenuLayout />

  <main class="flex-shrink-0">
    <HeaderLayout :name="name" />

    <div class="container mt-4">
      <h5>Profile</h5>
      <div class="card mt-3 mb-4">
        <div class="card-body">
          <div v-if="userInfo && userInfo.length > 0" class="row">
            <div v-for="(data, index) in userInfo"
            :key="index" class="col-12 col-md-6">
              <div class="form-group floating-form-group active">
                <p class="form-text">{{ data.value }}</p>
                <label class="floating-label">{{ data.title }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import MenuLayout from "../components/MenuLayout.vue";
import HeaderLayout from "../components/HeaderLayout.vue";
import { config } from '../config.js';

export default {
  name: "UserDashboard",
  components: { MenuLayout, HeaderLayout },
  data() {
    return {
      userInfo: {},
      name: '',
    };
  },
  async created() {
    try {
      const status = localStorage.getItem('status');
      const username = localStorage.getItem('username');
      if (status != 'valid') {
        this.$router.push('/');
      }

      // Contoh API call (ganti dengan URL backend Anda)
      const response = await fetch(config.apiUrl+'/api/dashboard', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username })
      });

      if (response.ok) {
        const data = await response.json();
        const res = data.results;
        this.userInfo = res;
        const name = res.find(item => item.title === 'Nama')?.value || '';
        this.name = name;
        localStorage.setItem('profileName', name);
      } else {
        this.$router.push('/');
      }
    } catch (err) {
      this.$router.push('/');
    }
  },
};
</script>
